import React from "react";
import Layout from "../../components/layout";
import riskAnalyzer from "../../assets/images/risk-analyzer.svg";
import riskAnalyzerHeader from "../../assets/images/risk-analyzer-header.svg";
import { Row, Col } from "react-bootstrap";
import NewNavbar from "../../components/navbar";

class BlogDetailPage extends React.Component {
  render() {
    return (
      <Layout pageInfo={{ pageName: "risk-analyzer" }}>
        <NewNavbar />
        <div className="page-container">
          <Row className="mt100">
            <Col
              className={"inline-pages-content-box service-detail"}
              md={{ span: 12 }}
            >
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <p className="title">Data-driven protocol risk analysis</p>
                  <p>
                    Risklick uses explainable AI to design and optimize clinical
                    trial protocols based on empirical data. Risklick-AI
                    empowers clinical trial professionals to optimize the
                    medical and operational aspects of any clinical trial
                    protocol before it starts, so you can conduct cheaper,
                    faster, safer trials. Risklick-AI’s multilayer analysis
                    identifies weak points in your proposed study, giving you
                    the information you need, optimize your protocol, avoid
                    delays and prevent deviations.
                  </p>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={riskAnalyzerHeader} className="header-img" />
                </Col>
              </Row>

              <Row>
                <Col className="text-center">
                  {/* <p className="mb-0">
                    Risklick-AI is the first of its kind, data-driven tool that
                    integrates data science, artificial intelligence and medical
                    science to help clinical trial professionals assess and
                    mitigate risks at any stage of the trial protocol
                    development. Our technology ensures the detection and
                    mitigation of risks negatively influencing the probability
                    of success (POS) of your clinical trial. Latest clinical
                    trial guidelines call for the implementation of clinical
                    trial risks assessment, oversight, and monitoring. However,
                    clinical trial protocol risk analysis is a complex,
                    time-consuming task that requires important investments from
                    clinical trial professionals. This is particularly true when
                    such analysis is performed without an established and
                    efficient methodology.
                  </p>
                  <img src={riskAnalyzer} className="mb50" /> */}
                  <p>
                    Risklick-AI optimizes:
                    <ul>
                      <li>Eligibility criteria</li>
                      <li>Outcomes</li>
                      <li>Sample size</li>
                      <li>Study duration </li>
                      <li>Readability</li>
                      <li>Safety</li>
                      <li>And other critical quality factors.</li>
                    </ul>
                    We make the risk mitigation process easy for study teams to
                    understand. Our comprehensive and actionable risk analysis
                    report includes corrective measures and suggestions to
                    improve quality. Boost the probability of a successful trial
                    now!
                    {/* <ul>
                      <li>To reduce the duration of protocol development</li>
                      <li>To diminish the likelihood of amendments</li>
                      <li>To increase the recruitment rate of participants</li>
                      <li>To reduce the likelihood of protocol violation</li>
                    </ul>
                    Using our cutting-edge technology, we offer a unique
                    opportunity to optimize clinical trial protocols, while
                    fulfilling the international guidelines requirements. */}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default BlogDetailPage;
